import React, { useEffect, useState } from "react";
import configs from "../../../../configs/apiConfigs";
import { getPatientClinicList } from "../../../services/patientchat";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";

export const BookAppointmentClinicDisplay = ({ handleModalClose }: any) => {
  const [clinicList, setClinicList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getPatientClinicList()
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          console.log("clinicList", success);
          setClinicList(success.clinics);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Book Appointment</h5>
      </div>
      <div className="offcanvas-body">
        <div className="triage_form">
          <div className="mb-3">
            {/* <label className="form-label">Clinic</label>
            <select disabled className="form-control" name="clinicId">
              <option>
                {clinicList && clinicList.name ? clinicList.name : ""}
              </option>
            </select> */}
            {clinicList.length !== 0 &&
              clinicList.map((clinic: any) => {
                return (
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="d-flex m-2">
                        <div className="avatar avatar-xl img-circle">
                          {clinic &&
                          clinic.logo &&
                          clinic.logo.name &&
                          clinic.logo.type ? (
                            <img
                              src={
                                configs.IMAGE_URL +
                                "clinic/" +
                                clinic.logo.name +
                                "-200x200." +
                                clinic.logo.type
                              }
                            />
                          ) : clinic.name ? (
                            clinic.name.charAt(0)
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          <h5 className="card-title">{clinic.name}</h5>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mb-2">
                        <a
                          href={clinic.url}
                          target="_blank"
                          className="btn btn-primary"
                        >
                          Book Appointment
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
