/* export const Referral = () => {
    const [isShowReferFriend, setIsShowReferFriend] = useState(false);
    return (
        <>

            <div className="card_item staticad card p-3">
                <div className="infocard_header mb-2">
                    <div className="card_ititle">Give $50. Get $50. </div>
                    <div className="card_desc">
                        <label className="text-dark opacity-75">Coming soon...</label>
                    </div>
                </div>

                <div className="card_ilink"></div>

            </div>


            <SharedOffCanvas
                isOpen={isShowReferFriend}
                handleClose={() => { setIsShowReferFriend(false) }}
                Children={ReferFriend}
                handleSuccess={() => {
                    setIsShowReferFriend(false)
                }}
            />
        </>
    )
} */

import React, { useState } from "react";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { ReferFriend } from "../xcarepoints/ReferFriend";
import { useHistory } from "react-router-dom";

export const MyOffers = (props: any) => {
  const [isShowReferFriend, setIsShowReferFriend] = useState(false);
  const history = useHistory();

  return (
    <>
      <div className="card_item rew_loy_point card justify-content-between p-2">
        <div className="card_box">
          <div className="infocard_header mb-2">
            <div className="card_ititle">My Offers</div>
            <div className="card-ititle opacity-75 mt-2">
              {/* You don't have any Rewards or Loyalty points. */}
              Coming soon...
            </div>
          </div>
          {/* <div
          className="infocard-body"
          onClick={() => {
            setIsShowReferFriend(true);
          }}
        >
          <div className="av-txt">
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => {
                setIsShowReferFriend(true);
              }}
            >
              <div className="av_title p-0"> Refer and Earn</div>
            </button>
            <div className="av_subtitle">Give & Get Points</div>
          </div>
        </div>
        </div>
        <div className="card_ilink fix_bottom"> */}
          {/* <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7 me-3"
            onClick={() => {
              setIsShowReferFriend(true);
            }}
          >
            Refer a Friend
          </button> */}
          {/* <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7"
            onClick={() => {
              history.push({ pathname: "/offers" });
            }}
          >
            View all offers
          </button> */}
        </div>
      </div>
      <SharedOffCanvas
        isOpen={isShowReferFriend}
        handleClose={() => {
          setIsShowReferFriend(false);
        }}
        Children={ReferFriend}
        handleSuccess={() => {
          setIsShowReferFriend(false);
        }}
        setIsShowPoints={props.setIsShowPoints}
      />
    </>
  );
};
